import { CountryCode, PhoneNumber, parsePhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import countryConfig, { CountryConfig, DefaultCountry } from "./countryConfig";

type PhoneValidationResult = { success: false, error: string, newCountryCode?: string } | { success: true, parsedPhone: PhoneNumber, newCountryCode?: string };

export const extractCountryConfig = (phone: string) => {
    if (phone) {
        try {
            const parseResult = parsePhoneNumber(phone);
            if (!parseResult) {
                return DefaultCountry;
            }
            const phoneCountry = parseResult.country;
            if (!phoneCountry) {
                return DefaultCountry;
            }
            const config = countryConfig.find((country) => country.code === phoneCountry);
            if (config) {
                return config;
            }
        }
        catch (e: any) {
            // console.log('initial country not found', e);
            return DefaultCountry;
        }
    }
    return DefaultCountry;
};

//DEPRECATED: This was built for the Booking Platform, and previously had a usage here.
//            We now use intl phone format, and this is no longer needed.
//            If we build a combo country+phone input, this could be restored.
export const extractFormattedPhone = (phone: string, countryCode: CountryCode) => {
    if (!phone) return '';
    try {
        const parseResult = parsePhoneNumber(phone, countryCode);
        if (!parseResult) {
            return '';
        }
        if (!parseResult.isValid()) {
            // Attempt to 'fix' an island nation with an unsupported country code, e.g. American Samoa.
            // See: https://groups.google.com/g/libphonenumber-discuss/c/YOJu6AdXMbU
            const cleaned = removeUnsupportedPrefix(phone);
            return cleaned ? cleaned : phone;
        }
        return parseResult.formatNational(); // (808) 264-3099
    }
    catch (e: any) {
        // console.log('initial phone phone not found:', e);
        return '';
    }
};

const MAX_STRIPE_PHONE_LENGTH = 20; // from their API errors

export function parsePhoneForStripe(phone: string, countryCode?: CountryCode) {
    // eslint-disable-next-line no-undef-init
    let output = undefined;
    try {
        const stripeFormattedPhone = parsePhoneNumber(phone, countryCode)?.formatInternational();
        if (stripeFormattedPhone && stripeFormattedPhone.length <= MAX_STRIPE_PHONE_LENGTH) {
            output = stripeFormattedPhone;
        }
    }
    catch (e: any) {
        // console.error('Error parsing phone for Stripe:', e);
    }
    return output;
}

export const validatePhoneNumber = (phone: string, countryCode: CountryCode): PhoneValidationResult => {
    try {
        if (phone) {
            const parsedPhone = parsePhoneNumberWithError(phone, { defaultCountry: countryCode, extract: false });

            //NOTE: This 'country' isn't 100% reliable. For example, the lib decides certain inputs are US numbers, e.g. 8085551212,
            //      even if it's passed in with country: American Samoa.
            //      For that reason, we only allow the country to be changed if the user manually adds their own country code.
            const countryChangeAllowed = phone.trimStart().charAt(0) === '+';
            const newCountry = (countryChangeAllowed && parsedPhone && parsedPhone.country !== countryCode) ? parsedPhone.country : undefined;
            if (parsedPhone) {
                if (parsedPhone.isValid()) {
                    return {
                        success: true,
                        parsedPhone: parsedPhone,
                        newCountryCode: newCountry
                    };
                }

                // Attempt to 'fix' an island nation with an unsupported country code, e.g. American Samoa.
                // See: https://groups.google.com/g/libphonenumber-discuss/c/YOJu6AdXMbU
                const cleaned = removeUnsupportedPrefix(phone);
                if (cleaned !== null) {
                    const subResult = validatePhoneNumber(cleaned, countryCode);
                    if (subResult.success) {
                        return subResult;
                    }
                }
            }
            return {
                success: false,
                error: 'Please enter a valid phone number.',
                newCountryCode: newCountry
            };
        }
        return {
            success: false,
            error: 'Please enter a phone number.'
        };
    } catch (e: any) {
        return {
            success: false,
            error: 'Phone number is not valid.'
        };
    }
};

export const writePhoneForSFAccount = (config: CountryConfig, phone: string) => {
    //NOTE: We now use the intl format at all levels.
    const sfPhone = config.dialCode ? `${config.dialCode} ${phone}` : phone;
    return sfPhone;
};

export const getCountryFromPhone = (phone: string): string => {
    try {
        if (phone) {
            const parsedPhone = parsePhoneNumberWithError(phone, { defaultCountry: 'US', extract: false });
            if (parsedPhone) {
                return parsedPhone.country || 'US';
            }
        }
    } catch (e: any) {
        // phone number appears invalid
    }
    return 'US';
};

// this is a local helper for island nations that our library doesn't handle
function removeUnsupportedPrefix(phoneNumber: string) {
    const str = phoneNumber.trim();
    if (str.startsWith('+')) {
        // Check if the plus sign is followed by four digits and then a space
        const match = str.match(/^\+(\d{4})\s/);
        if (match) {
            return str.slice(match[0].length);
        }
    }
    return null;
}
