import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Link } from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import { useSettingsContext } from '../settings';
import { brandConfig } from '../../config';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
	hidden?: boolean,
	disabledLink?: boolean;
	full?: boolean
	height?: number,
	width?: number
}

/**
 * @param disabledLink will disable navigation when clicked. Helpfull on OOB action pages.
 * @param full will determain which Logo will be shown.
 * @param height vertical size
 * @param width horizontal size
 */
const Logo = forwardRef<HTMLAnchorElement, LogoProps>(
	({
		hidden = false,
		disabledLink = false,
		full = false,
		height,
		width,
		sx,
		...other }, ref) => {
		// const theme = useTheme()
		const { themeMode } = useSettingsContext()
		if (hidden) {
			return null;
		}
		// const PRIMARY_MAIN = theme.palette.primary.main
		// const SUCCESS_MAIN = theme.palette.success.main
		// using local (public folder)
		// -------------------------------------------------------
		const logo = !full ? (
			<Box
				component="img"
				src={themeMode !== 'dark' ? brandConfig.iconUrl : brandConfig.iconUrlDark}
				sx={{
					width: width || 40,
					height: height || 40,
					// cursor: 'pointer',
					display: 'inline-flex',
					...sx
				}}
				{...other}
			/>
		) : (
			<Box
				component="img"
				src={themeMode !== 'dark' ? brandConfig.logoUrl : brandConfig.logoUrlDark}
				sx={{
					width: width || 240,
					cursor: 'pointer',
					display: 'inline-flex',
					...sx
				}}
			/>
		);

		if (disabledLink) {
			return logo;
		}

		return (
			<Link
				ref={ref}
				component={NavLink}
				to={PATH_APP.app}
				sx={{
					display: 'contents',
					cursor: 'pointer',
				}}>
				{logo}
			</Link>
		);
	}
);

export default Logo;
