import { Button, Stack, Typography } from '@mui/material';
import { ConfirmationResult } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import LoginFlowProvider, { SignInMethod, SignInStep, useLoginFlow } from '../../auth/LoginProvider';
import ConfirmCode from '../../components/login/ConfirmCode';
import SelectVerificationMethod from '../../components/login/SelectVerificationMethod';
import Logo from '../../components/logo';
import { CheckEnvironment, SupportedEnvironments } from '../../config-global';
import LoginLayout from '../../layouts/login/LoginLayout';
import AuthLoginForm from '../../sections/auth/AuthLoginForm';
import { VerifyCredentialForm } from '../../sections/auth/VerifyCredentialForm';
import fTitle from '../../utils/mrr/formatTitle';

export default function OobSignInPage() {
  return (
    <>
      <Helmet>
        <title>{fTitle('Login')}</title>
      </Helmet>
      <div id='hidden-recaptcha-element' />
      <LoginFlowProvider>
        <LoginWithEmailOrPhone />
      </LoginFlowProvider>
    </>
  );
}

// ----------------------------------------------------------------------

function LoginWithEmailOrPhone() {
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setAlertFromOOBAction,
    signInStep,
    setSignInStep,
    setConfirmationResult,
    phoneToVerify
  } = useLoginFlow();

  useEffect(() => {
    if (state) {
      if (state.error) {
        enqueueSnackbar(state.error, { autoHideDuration: 6 * 1000, variant: 'error' });
      };
      if (state.alert) {
        setAlertFromOOBAction(state.alert);
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, enqueueSnackbar]);

  return (
    <LoginLayout>
      <Stack
        direction='row'
        position='relative'
        alignItems='center'
        justifyContent='flex-start'
        mb={2.5}
      >
        <Logo
          disabledLink
          height={28}
          width={28}
          mr={1}
        />
        <Typography variant="h4">Guest Portal Login</Typography>
      </Stack>

      {signInStep === SignInStep.Initial &&
        <VerifyCredentialForm next={(method: SignInMethod) => {
          setSignInStep(SignInStep.SelectMethod);
        }} />}
      {signInStep === SignInStep.SelectMethod &&
        <SelectVerificationMethod
          next={(confirmResult?: ConfirmationResult) => {
            setSignInStep(SignInStep.Confirm);
            setConfirmationResult(confirmResult)
          }}
          back={() => { setSignInStep(SignInStep.Initial) }} />}
      {signInStep === SignInStep.Confirm &&
        <ConfirmCode
          back={() => {
            setSignInStep(SignInStep.Initial);
          }}
        />}

      {signInStep === 'Developer Login' && <AuthLoginForm
        back={() => { setSignInStep(SignInStep.Initial) }}
      />}
      {(CheckEnvironment(SupportedEnvironments.Dev) && signInStep === SignInStep.Initial) &&
        <Button
          color='warning'
          sx={{ mt: 2 }}
          variant='soft'
          onClick={() => { setSignInStep("Developer Login") }}>
          Developer Login
        </Button>
      }
    </LoginLayout>
  )
}