import {
  AppBar,
  IconButton,
  Stack,
  Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useAuthContext } from '../../../auth/useAuthContext';
import Iconify from '../../../components/iconify';
import LoadingScreen from '../../../components/loading-screen';
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
import { HEADER, NAV } from '../../../config-global';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { bgBlur } from '../../../utils/cssStyles';
import { hasSessionAdmin } from '../../../utils/mrr/emulator/signInAsOtherUser';
import { roleCanSeePortalNotifications } from '../../../utils/mrr/userConstants';
import AccountPopover from './AccountPopover';
import ReturnToAdminPopover from './ReturnToAdminPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

type Props = {
  // showNotifications: boolean,
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav/*, showNotifications*/ }: Props) {
  const theme = useTheme();
  const { user } = useAuthContext()
  const isOwner = user ? roleCanSeePortalNotifications(user.role) : true;

  //PNs TBD
  // const noticeControls = usePortalNotificationContext()
  // const { childrenTopMargin } = noticeControls
  // const notificationMargin = showNotifications
  //   ? childrenTopMargin
  //   : 0
  const notificationMargin = 0;

  const { themeLayout } = useSettingsContext();

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const isSlim = useResponsive('down', 350);
  const hasAdmin = hasSessionAdmin();
  const isMobileWithAdmin = isMobile && hasAdmin;

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP);

  const [loggingOutOfOtherUser] = useState(false)

  const renderContent = (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* Only lets us browse (all) routes. Does more harm than good in this app.
          The styling is nice, if this could come back for a more useful search feature.
      */}
      {/* <Searchbar /> */}

      {/* Show our logo when the permanently-open drawer is hidden. */}
      {!isDesktop && <Logo width={isMobileWithAdmin ? 180 : undefined} disabledLink full={!isSlim} />}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, sm: 1.5 }}
      // spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}

        {hasAdmin && <ReturnToAdminPopover />}

        {/* <ContactsPopover /> */}

        {/* <CartPopover /> */}
        <AccountPopover />
      </Stack>
    </Stack>
  );

  if (loggingOutOfOtherUser) {
    return <LoadingScreen />
  }

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
