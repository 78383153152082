import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Link, Stack, Typography, styled } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import { SignInMethod, useLoginFlow } from '../../auth/LoginProvider';
import { RHFTextField } from '../../components/hook-form';
import FormProvider from '../../components/hook-form/FormProvider';
import Iconify from '../../components/iconify/Iconify';
import { MenuPopoverProps } from '../../components/menu-popover';
import { brandConfig } from '../../config';
import { ContactUsPopover } from '../../layouts/dashboard/header/ContactUsPopover';
import { cloud_verifyEmailOrPhone } from '../../utils/mrr/cloudFunctions';
import { validatePhoneNumber } from '../../utils/mrr/phone/phone';
import { EMAIL_REGEX } from '../../utils/mrr/uiConstants';

interface VerifyCredentialFormFieldValues {
    emailOrPhone: string;
}

const VerifyCredentialFormScheme = Yup.object().shape({
    emailOrPhone: Yup
        .string()
        .required('Email or phone number required')
        .test(
            'valid-email-or-phone', // test name
            'The email or phone number must be valid.',
            (value) => {
                const validAsEmail = value.match(EMAIL_REGEX) !== null;
                const phoneValidation = validatePhoneNumber(value, 'US');
                const validCredential = validAsEmail || phoneValidation.success;
                return validCredential;
            })
});

type VerifyCredentialFormProps = {
    next(method: SignInMethod): void
}

export function VerifyCredentialForm({ next }: VerifyCredentialFormProps) {
    const [searchParams] = useSearchParams()
    const { emailToVerify, setEmailToVerify, setPhoneToVerify, alertFromOOBAction, setSignInMethod } = useLoginFlow();

    useEffect(() => {
        if (alertFromOOBAction) {
            window.history.replaceState(null, '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertFromOOBAction])
    const defaultFormEmail = searchParams.has('email') ? searchParams.get('email')! : emailToVerify

    const methods = useForm<VerifyCredentialFormFieldValues>({
        resolver: yupResolver(VerifyCredentialFormScheme),
        defaultValues: { emailOrPhone: defaultFormEmail },
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isSubmitting, isValid },
        trigger,
        setError
    } = methods

    useEffect(() => {
        // This will validate the input email if prefilled by search params
        if (defaultFormEmail !== '') {
            trigger()
        }
    }, [defaultFormEmail, trigger])

    const onSubmit = handleSubmit(async (data) => {
        try {
            const request = httpsCallable<{ credential: string, brand: string }, Record<string, any>>(CLOUD_FUNCTIONS, cloud_verifyEmailOrPhone);
            const verifcationResponse = (await request({ credential: data.emailOrPhone, brand: brandConfig.brandCode }));
            const { method, email, phone, success, error } = verifcationResponse.data;

            if (error) {
                setError('emailOrPhone', { message: 'Please try again.' });
                return;
            }

            if (!success) {
                setError('emailOrPhone', { message: 'Account not found.' });
                return;
            }

            if (method === 'email') {
                setEmailToVerify(email || '');
            }
            else {
                setPhoneToVerify(phone || '');
            }

            const emailBasedOTP = method === 'email';
            if (emailBasedOTP) {
                setSignInMethod(SignInMethod.Email);
            }
            else {
                setSignInMethod(SignInMethod.Phone);
            }
            next(method);
        }
        catch(caught: any) {
            const pauser = 1;
            console.log('caught', caught, pauser);
            setError('emailOrPhone', { message: getErrorText(caught) });
        }
    })
    return (
        <FormProvider name='login_form' id='login_form' methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
                <WelcomeAlert />
                <StyleInput
                    disabled={isSubmitting}
                    InputProps={
                        {
                            endAdornment:
                                <Iconify
                                    color='inherit'
                                    icon='eva:person-outline'
                                    ml={1} />
                        }
                    }
                    name='emailOrPhone'
                    label="Email or phone"
                    type='text'
                    autoFocus />

                <Stack direction='column' spacing={1}>
                    <LoadingButton
                        disabled={isSubmitting || !isValid}
                        size='large'
                        loading={isSubmitting}
                        type="submit"
                        variant='contained'
                    >
                        Continue
                    </LoadingButton>

                    <HelpLink arrow='top-left' />
                </Stack>
            </Stack>
        </FormProvider>
    );
}

//-----------------

const StyleInput = styled(RHFTextField, {
})(({ theme }) => ({
    input: {
        '&:-webkit-autofill': {
            webkitBoxShadow: 'none !important',
            webkitTextFillColor: 'inherit',
            webkitBackgroundClip: 'text !important'
        }
    }
}))


function WelcomeAlert() {
    return (
        <Alert severity="info">
            <Stack>
                <Typography variant='h6'>
                    Aloha and Welcome!
                </Typography>
                <Typography>
                    Please enter your email or phone number to continue.
                </Typography>
            </Stack>
        </Alert>
    )
}

function HelpLink({ arrow }: { arrow?: MenuPopoverProps['arrow'] }) {
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const handleOpenContactUs = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setOpen(event.currentTarget)
    }
    const handleCloseContactUs = () => {
        setOpen(null)
    }

    return (
        <>
            <Link
                sx={{ cursor: 'pointer' }}
                onClick={handleOpenContactUs}
                color='primary'>
                Help?
            </Link>
            <ContactUsPopover arrow={arrow} onClose={handleCloseContactUs} open={open} />
        </>
    )
}

function getErrorText(e: any) {
    if (e
        && e.details
        && e.details.error
        && e.details.error.message) {
        return e.details.error.message;
    }
    return 'Unable to verify guest.';
}
