import { Helmet } from 'react-helmet-async';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { Link, Typography } from '@mui/material';
// routes
import { httpsCallable } from 'firebase/functions';
import { PATH_AUTH, PATH_APP } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
// assets
import { EmailInboxIcon } from '../../assets/icons';
import { useAuthContext } from '../../auth/useAuthContext';
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import fTitle from '../../utils/mrr/formatTitle';
import { sendToSentry, sentryOOBActionsFingerprint } from '../../utils/mrr/sentryReporter';

// ----------------------------------------------------------------------

export default function MRRNotVerifiedPage() {
    const { logout, user } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar()

    //NOTE: Not used in this portal. We use login via link, which verifies the user.
    // const handleResendEmailClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     if (!user) {
    //         return
    //     }
    //     try {
    //         const response = await httpsCallable(CLOUD_FUNCTIONS, cloud_resendWelcomeEmail)()
    //         const data = response.data as any
    //         if (!data || !data.email) {
    //             throw new Error('Failed to resend email.')
    //         }
    //         enqueueSnackbar(`Email sent to ${data.email}`)
    //     } catch (error) {
    //         //console.error(error)
    //         sendToSentry(error, null, sentryOOBActionsFingerprint, 'resendWelcomeEmail')
    //         enqueueSnackbar("Failed to resend email.")
    //     }
    // }

    if (!user?.email || user?.emailVerified) {
        // This will handle a verified user landing on this url somehow.
        return <Navigate to={PATH_APP.app} />
    }

    return (
        <>
            <Helmet>
                <title>{fTitle('Not Verified')}</title>
            </Helmet>

            <EmailInboxIcon sx={{ mb: 5, height: 96 }} />

            <Typography variant="h3" paragraph>
                Please check your email!
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                In order to continue with this application we must verify your email address.
            </Typography>

            {/* NOTE: Not used in this portal. We use login via link, which verifies the user.
            <Typography variant="body2" sx={{ my: 3 }}>
                Don’t have an email? &nbsp;
                <Link
                    to="."
                    variant='subtitle2'
                    component={RouterLink}
                    onClick={handleResendEmailClick}
                >
                    Resend email
                </Link>
            </Typography>
            */}

            <Link
                onClick={logout}
                component={RouterLink}
                to='/'
                color="inherit"
                variant="subtitle2"
                sx={{
                    mx: 'auto',
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:chevron-left-fill" width={16} />
                Return to sign in
            </Link>
        </>
    );
}