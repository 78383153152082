import { useTheme } from "@mui/material";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AUTH } from "./FirebaseContext";

const verboseLogging = false;

export enum SignInMethod {
    Email = 'email',
    Phone = 'phone'
};

export enum SignInStep {
    Initial,
    SelectMethod,
    Confirm
}

type LoginFlowStateT = {
    alertFromOOBAction: string | null,
    setAlertFromOOBAction: Dispatch<SetStateAction<string | null>>,
    signInMethod: SignInMethod | null,
    setSignInMethod: Dispatch<SetStateAction<SignInMethod | null>>,
    emailToVerify: string,
    setEmailToVerify: Dispatch<SetStateAction<string>>,
    phoneToVerify: string,
    setPhoneToVerify: Dispatch<SetStateAction<string>>,
    signInStep: SignInStep | 'Developer Login',
    setSignInStep: Dispatch<SetStateAction<SignInStep | 'Developer Login'>>,
    confirmationResult?: ConfirmationResult,
    setConfirmationResult: Dispatch<SetStateAction<ConfirmationResult | undefined>>,
    recaptcha?: RecaptchaVerifier
};
const initialState: LoginFlowStateT = {
    alertFromOOBAction: null,
    setAlertFromOOBAction: () => { },
    signInMethod: null,
    setSignInMethod: () => { },
    emailToVerify: '',
    setEmailToVerify: () => { },
    phoneToVerify: '',
    setPhoneToVerify: () => { },
    signInStep: SignInStep.Initial,
    setSignInStep: () => { },
    confirmationResult: undefined,
    setConfirmationResult: () => { },
};

const LoginFlowState = createContext<LoginFlowStateT>(initialState);

const LoginFlowProvider = ({ children }: PropsWithChildren) => {
    const [alertFromOOBAction, setAlertFromOOBAction] = useState<string | null>(null);
    const [signInMethod, setSignInMethod] = useState<SignInMethod | null>(null);
    const [emailToVerify, setEmailToVerify] = useState('');
    const [phoneToVerify, setPhoneToVerify] = useState('')
    const [signInStep, setSignInStep] = useState<SignInStep | 'Developer Login'>(SignInStep.Initial);
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>()
    const theme = useTheme();
    useEffect(() => {
        if (!phoneToVerify) {
            return;
        }
        setRecaptcha((prev) => {
            if (prev) {
                prev.clear();
            }

            return new RecaptchaVerifier(
                document.getElementById('hidden-recaptcha-element') || 'hidden-recaptcha-element', // invisible mode requires an element present in the DOM
                {
                    'size': 'invisible',
                    'theme': theme.palette.mode || 'light',
                    'callback': (response: any) => {
                        verboseLogging && console.log('recaptcha callback passed/solved', response);
                    },
                    'expired-callback': () => {
                        console.warn('recaptcha expired', phoneToVerify);
                    },
                },
                AUTH
            );
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneToVerify]);
    const value = useMemo(() => ({
        alertFromOOBAction,
        setAlertFromOOBAction,
        signInMethod,
        setSignInMethod,
        emailToVerify,
        setEmailToVerify,
        phoneToVerify,
        setPhoneToVerify,
        signInStep,
        setSignInStep,
        confirmationResult,
        setConfirmationResult,
        recaptcha
    }), [
        alertFromOOBAction,
        setAlertFromOOBAction,
        signInMethod,
        setSignInMethod,
        emailToVerify,
        setEmailToVerify,
        phoneToVerify,
        setPhoneToVerify,
        signInStep,
        setSignInStep,
        confirmationResult,
        setConfirmationResult,
        recaptcha
    ])
    return (
        <LoginFlowState.Provider value={value}>
            {children}
        </LoginFlowState.Provider>
    )
};

export const useLoginFlow = () => {
    const context = useContext(LoginFlowState);
    if (!context) {
        throw new Error('Hook must be called inside of LoginFlowProvider.')
    }
    return context;
};

export default LoginFlowProvider;