import { Alert, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { ConfirmationResult, signInWithPhoneNumber } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useEffect, useRef, useState } from "react";
import { AUTH, CLOUD_FUNCTIONS } from "../../auth/FirebaseContext";
import { SignInMethod, useLoginFlow } from "../../auth/LoginProvider";
import { brandConfig } from "../../config";
import { cloud_emailOneTimePassword } from "../../utils/mrr/cloudFunctions";


type SelectVerificationMethodProps = {
    next(confirm?: ConfirmationResult): void,
    back(): void,
};

const SelectVerificationMethod = ({ next, back }: SelectVerificationMethodProps) => {
    const { emailToVerify, phoneToVerify, recaptcha, signInMethod } = useLoginFlow();
    const [error, setError] = useState<string | null>(null);
    const codeSent = useRef<boolean>(false);

    // this one-time effect doesn't work wrt recaptcha, so we check that as a dependency, and use a REF to gate
    useEffect(() => {
        if (codeSent.current) {
            return;
        }
        const SendOnce = async () => {
            if (signInMethod === SignInMethod.Email) {
                codeSent.current = true;
                const sendEmailCodeRequest = httpsCallable<{ email: string, brand: string }, boolean>(CLOUD_FUNCTIONS, cloud_emailOneTimePassword);
                const emailCodeResult = await sendEmailCodeRequest({ email: emailToVerify, brand: brandConfig.brandCode });
                const sentSignInEmailCode = emailCodeResult.data;
                if (!sentSignInEmailCode) {
                    setError('Unable to send sign-in code. Please try again.');
                    return;
                }
                next();
                return
            }
            if (signInMethod === SignInMethod.Phone && recaptcha) {
                codeSent.current = true;
                await signInWithPhoneNumber(AUTH, phoneToVerify, recaptcha).then(async (nextPayload) => {
                    next(nextPayload);
                }).catch((e) => {
                    if (e instanceof FirebaseError && e.code === 'auth/too-many-requests') {
                        setError('Too many attempts. Please try again later.');
                        return;
                    }
                    setError('Sorry we could not send you a code. Please try again.');
                })
                // eslint-disable-next-line no-useless-return
                return;
            }
        };

        SendOnce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [recaptcha]);

    return (
        <Stack spacing={2} direction='column' justifyContent='space-between' minHeight={260}>
            {!error &&
                <>
                    <Typography>
                        Sending login code...
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <br/>
                        <br/>
                        <CircularProgress color='primary' variant='indeterminate' />
                        <br/>
                        <br/>
                    </div>
                </>
            }
            {error && <Alert severity="warning">
                {error}
            </Alert>}
            <Link onClick={back} sx={{ width: 'fit-content', cursor: 'pointer' }}>Back</Link>
        </Stack>
    )
};

export default SelectVerificationMethod;
